import copyToClipboard from "copy-to-clipboard";
import { noop } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { chatRoomApi } from "@/react/api";
import { embeddableChatApiQueryKey } from "@/react/hooks/embeddable_chat/useEmbeddableChatsApi";
import { TippyV2 } from "@circle-react/components/shared/TippyV2";
import {
  useCurrentSpaceContext,
  usePunditUserContext,
} from "@circle-react/contexts";
import {
  MESSAGING_MODAL_QUERY_KEY,
  useChatStore,
} from "@circle-react/hooks/chatsV2";
import { useSpaceSidebarNotifications } from "@circle-react/hooks/useSpaceSidebarNotifications";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { useToast } from "@circle-react-uikit/ToastV2";
import {
  getActiveMessageId,
  getCopyLinkUrl,
  getDefaultMessagePortalId,
} from "../../helpers";

export interface DropdownActionsProps {
  canUserModifyMessage?: boolean;
  chatRoom: any;
  currentParticipant?: any;
  isMessageThread?: boolean;
  isPinned?: boolean;
  message: any;
  isLiveStream?: boolean;
  setEditMessageId: (id: number) => void;
  setUnreadMessageFlow?: (value: boolean) => void;
  toggleDeleteConfirmationModal: () => void;
  chatRoomUrl?: string;
  messagesContainerId?: string;
  setActiveMessageId: (id: string) => void;
}

export const DropdownActions = ({
  isPinned = false,
  setEditMessageId,
  currentParticipant = {},
  toggleDeleteConfirmationModal,
  message,
  chatRoom,
  isMessageThread = false,
  canUserModifyMessage = false,
  setUnreadMessageFlow = noop,
  isLiveStream = false,
  chatRoomUrl = "",
  messagesContainerId = "",
  setActiveMessageId,
}: DropdownActionsProps) => {
  const { pathname } = useLocation();
  const { currentCommunity } = usePunditUserContext();

  const isMessagingPage = pathname.includes("/messages");
  const { appendUnreadChatRoomUuids } = useChatStore();
  const {
    id,
    chat_room_participant_id: messageSenderId,
    parent_message_id: parentMessageId,
  } = message;
  const { admin, id: currentParticipantId } = currentParticipant;
  const isCurrentParticipantSender = messageSenderId === currentParticipantId;
  const toastr = useToast();
  const isDirectChatRoom = chatRoom.chat_room_kind == "direct";
  const { data: currentSpace } = useCurrentSpaceContext();
  const { refetchSpaceNotifications } = useSpaceSidebarNotifications([
    currentSpace?.id,
  ]);
  const queryClient = useQueryClient();

  const shouldHideMarkUnreadButton =
    isCurrentParticipantSender || chatRoom.lesson || isLiveStream;

  const setPinnedMessage = (messageId: any) => {
    pinMessage({ pinned_message_id: isPinned ? null : messageId });
  };

  const setMarkAsUnread = (messageId: any) => {
    markAsUnread({ message_id: messageId });
    if (isMessagingPage && currentCommunity?.id) {
      appendUnreadChatRoomUuids(currentCommunity.id, chatRoom.uuid);
    }
  };

  const copyLink = () => {
    const link = getCopyLinkUrl({
      id,
      parentMessageId,
      chatRoomUrl,
    });
    copyToClipboard(link);
    toastr.success(t("messaging.actions.copy_link_success"));
  };

  const refetchSidebarChatRooms = () => {
    void queryClient.invalidateQueries(MESSAGING_MODAL_QUERY_KEY);
  };

  const refetchChatRoom = () => {
    void queryClient.invalidateQueries(
      embeddableChatApiQueryKey(chatRoom.uuid),
    );
  };

  const { mutate: markAsUnread } = useMutation<any, unknown, any>(
    values =>
      chatRoomApi.markAsUnread({
        uuid: chatRoom.uuid,
        chat_room: values,
      }),
    {
      onSuccess: () => {
        setUnreadMessageFlow(true);
        refetchChatRoom();
        if (currentSpace) {
          void refetchSpaceNotifications();
        } else {
          refetchSidebarChatRooms();
        }
        toastr.success(t("messaging.actions.mark_as_unread_success"));
      },
      onError: () => {
        toastr.error(t("messaging.actions.mark_as_unread_error"));
      },
    },
  );

  const { mutate: pinMessage } = useMutation<any, unknown, any>(
    values =>
      chatRoomApi.pinMessage({
        uuid: chatRoom.uuid,
        chat_room: values,
      }),
    {
      onError: () => {
        toastr.error(t("messaging.actions.pin_error"));
      },
    },
  );

  const dropDownOptions = [
    {
      value: "edit-message",
      label: t("edit"),
      onClick: () => setEditMessageId(id),
      hide: !isCurrentParticipantSender,
    },
    {
      value: "pin-message",
      label: isPinned ? t("unpin") : t("pin"),
      onClick: () => setPinnedMessage(id),
      hide:
        !canUserModifyMessage ||
        isMessageThread ||
        (!admin && !isDirectChatRoom),
    },
    {
      value: "unread-message",
      label: t("unread"),
      onClick: () => setMarkAsUnread(id),
      hide: shouldHideMarkUnreadButton,
    },
    {
      value: "copy-link",
      label: t("messaging.actions.copy_link"),
      onClick: copyLink,
      hide: isLiveStream,
    },

    {
      value: "delete-message",
      label: t("delete"),
      onClick: toggleDeleteConfirmationModal,
      color: "text-v2-danger",
      hide: !canUserModifyMessage,
    },
  ];

  if (dropDownOptions.every(option => option.hide)) {
    return null;
  }

  const appendTo =
    (messagesContainerId && document.getElementById(messagesContainerId)) ||
    getDefaultMessagePortalId();

  const activeMessageId = getActiveMessageId(id, isMessageThread);

  return (
    <Dropdown
      direction="bottom-end"
      button={
        <Dropdown.MenuButton
          onClick={() => setActiveMessageId(activeMessageId)}
        >
          <TippyV2 content={t("messaging.more_actions")}>
            <IconButton
              name="16-menu-dots-horizontal"
              ariaLabel={t("messaging.more_actions")}
            />
          </TippyV2>
        </Dropdown.MenuButton>
      }
      options={dropDownOptions}
      dataTestId="message-actions"
      appendTo={appendTo}
    />
  );
};
